import icon from 'img/favicon/android-chrome-144x144.png';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  vapidKey: process.env.FIREBASE_APP_VAPID_KEY,
  link: 'https://talendo.ch/',
  icon
};
